<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer @click-submit="clickSubmit" width="700px"
                @click-cancel="clickCancel">
        <el-form title="" ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="公告标题" prop="title">
                <el-input v-model="formPublish.title"/>
            </el-form-item>
            <el-form-item label="公告内容" prop="details">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5}" v-model="formPublish.details"/>
            </el-form-item>
            <el-form-item label="内容发布人" prop="publisher">
                <el-input v-model="formPublish.publisher"/>
            </el-form-item>
            <el-form-item label="内容发布时间" prop="publishTime">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                                v-model="formPublish.publishTime" type="datetime" placeholder="选择日期时间"/>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-radio-group v-model="formPublish.status" size="small">
                    <el-radio-button :label="0">正常</el-radio-button>
                    <el-radio-button :label="1">下架</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {noticeAdd,noticeUpdate} from "@/api/notice-management";
    import {MessageSuccess, MessageWarning, MessageError} from "@custom/message";
    import { timeFormat, numberFormat } from "@custom/index";
    export default {
        name: "layer-add-update-notice",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    uuid: null,
                    title: null,
                    details: null,
                    publisher: null,
                    publishTime: null,
                    status: null,
                },
                rules: {
                    title: [{required: true, message: '请输入公告标题', trigger: 'blur'}],
                    details: [{required: true, message: '请输入公告内容', trigger: 'blur'}],
                    publisher: [{required: true, message: '请输入内容发布人', trigger: 'blur'}],
                    publishTime: [{required: true, message: '请选择内容发布时间', trigger: 'change'}],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                },
                dialogTitle: '',
                type: 'add',
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components: {},
        async mounted() {
        },
        methods: {
            openDialog(type, data) {
                this.type = type;
                this.dialogTitle = type === "add" ? '发布公告' : '修改公告';
                if (type === 'add') console.log(type);
                else {
                    let {publishTime} = data;
                    publishTime = timeFormat(new Date(publishTime), "yyyy-MM-dd HH:mm:ss");
                    let oldFormPublish = {...this.formPublish};
                    this.formPublish = {...oldFormPublish, ...data, publishTime}
                }
                this.dialogVisible = true;
            },

            clickSubmit() {
                // 表单提交
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let data = this.formPublish;
                        if (this.type === 'add') this.noticeAdd({...data});
                        else this.noticeUpdate({...data});
                    }
                });
            },
            clickCancel() {
                this.$emit("handleSearch");
                this.formPublish = {
                    uuid: null,
                    title: null,
                    details: null,
                    publisher: null,
                    publishTime: null,
                    status: null,
                }
                this.dialogVisible = false;
            },
            noticeAdd(data) {
                let that = this;
                let {publishTime, status} = data;
                publishTime = new Date(publishTime);
                let statusName = status === 0 ? "正常" : "下架";
                let loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                noticeAdd({...data, publishTime, statusName}).then(res => {
                    MessageSuccess('添加成功');
                    that.clickCancel();
                    loading.close();
                }).catch(err => loading.close());
            },
            noticeUpdate(data) {
                let that = this;
                let {publishTime,status} = data;
                publishTime=new Date(publishTime);
                let statusName = status === 0 ? "正常" : "下架";
                let loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                noticeUpdate({...data, publishTime, statusName}).then(res => {
                    MessageSuccess('修改成功');
                    that.clickCancel();
                    loading.close();
                }).catch(err => loading.close());
            },
        }
    }
</script>

<style scoped>

</style>